<template>
  <section id="company" ref="companyContent">
    <PageTitle :title="$t('company.title')" :breadcrumbs="breadcrumbs"></PageTitle>
    
    <div v-if="company != null" class="wrapper wrapper-content animated fadeInRight">

      <div class="row m-b-lg m-t-lg">
        <div class="col-md-6">
          <div v-if="company.logo" class="profile-image">
              <img :src="getStaticURLFromPath(company.logo.path)" class="rounded-circle circle-border m-b-md" alt="logo">
          </div>
          <div :class="(company.logo ? 'profile-info' : '')">
            <h2 class="no-margins">{{company.name}}</h2>
            <p>{{$t('company.info.number',{companyID: company._id})}}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <Info v-if="company != null" :company="company"></Info>
          <div v-if="company != null && company.users != null && company.users.length > 0">
            <ul>
              <li v-for="(user, index) in company.users" :key="index">
                <span>Login: {{user.login}}</span><br/>
                <span>Id: {{user._id}}</span><br/>
                <span v-if="user.profile != null && user.profile.phones != null && user.profile.phones.length > 0">
                  Phone: {{user.profile.phones[0].number}}<br/>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8">
          <ListInvoices :companyId="company._id.toString()" :showCompanyColumn="false"></ListInvoices>
          <ListWorkers :companyId="company._id.toString()" ></ListWorkers>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';  
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import PageTitle from '../panels/PageTitle.vue';
import Messages from '../panels/Messages.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../theme/inspinia-jquery'; 

@Component({
  components: {
    PageTitle,
    Info : () => import(/* webpackChunkName: "panels-company-info" */ '../panels/company/Info.vue'),
    ListInvoices : () => import(/* webpackChunkName: "panels-company-listInvoices" */ '../panels/company/ListInvoices.vue'),
    ListWorkers : () => import(/* webpackChunkName: "panels-company-listWorkers" */ '../panels/eurofiscalis/company/ListWorkers.vue')
  }
})
export default class Company extends mixins(GenericPage) {

  get breadcrumbs() {
    return [{
      label: this.$t('home.dashboard')
    },
    {
      label: this.$t('company.title')
    }]
  }

  company:any = null;

  created() {
    this.loadCompany(this.$route.params.id);
  }

  mounted() {
    enableIbox(this.$refs.companyContent as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.companyContent as HTMLElement);
  }

  loadCompany(companyId:any) {
    // We check if we have information from user profile
    if(this.$store.state.authentication.isLoggedIn &&
        this.$store.state.authentication.user.profile &&
        this.$store.state.authentication.user.profile.company && 
        (companyId == undefined || this.$store.state.authentication.user.profile.company._id == companyId)) {
      var company =  this.$store.state.authentication.user.profile.company;
      companyId = company._id;
    }

    // We load the company from backend
    var options:api.ApiVueOptions =  {
      app: this
    }

    api.getAPI('/api/companies/'+companyId, options).then((response:any) => {
      if(response.company) {  
        this.company = response.company;
      }
    })
  }

}
</script>